.hero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio */
  transform: translate(-50%, -50%);
  object-fit: cover;
  pointer-events: none;
}

/* Add a semi-transparent overlay */
.hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust opacity as needed */
  z-index: 2;
}

/* Ensure content is above the video and overlay */
.hero-content, header {
  position: relative;
  z-index: 3;
  color: white; /* Ensure text is visible */
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5); /* Improve readability */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  max-width: 1200px;
}

/* Style for the button to make it stand out */
.learn-more {
  background-color: #007bff; /* Example color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Add some space above the button */
}

.learn-more:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}
